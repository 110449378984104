/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import {
  faSort,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
import PaginationComp from './pagination';


const getHeaderColumn = (column) => {
  if (!column.disableSortBy) {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return (
          <div className="table__header">
            <span>{column.render("Header")}</span>
            <span>
              <FontAwesomeIcon icon={faSortDown} />
            </span>
          </div>
        );
      }
      return (
        <div className="table__header">
          <span>{column.render("Header")}</span>
          <span>
            <FontAwesomeIcon icon={faSortUp} />
          </span>
        </div>
      );
    }
    return (
      <div className="table__header">
        <span>{column.render("Header")}</span>
        <span>
          <FontAwesomeIcon icon={faSort} />
        </span>
      </div>
    );
  }
  return (
    <div>
      <span>{column.render("Header")}</span>
    </div>
  );
};

const DataTable = (props) => {
  const {
    columns,
    data,
    actionButtons,
    sortBy,
    showDateRangeSelector,
    dateRangeSelector,
    showInvoiceFilter = false,
    selectHandler = false,
    filterMenu = [],
    filterLabel = "",
  } = props;

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="table__wrapper">
      <div>
        <PaginationComp
        columns={props.columns}
        data={props.data}
        />
      </div>
    </div>
  );
};

export default DataTable;
