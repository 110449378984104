import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import {
  loginUserSuccess,
  userPreference,
  // eslint-disable-next-line prettier/prettier
  setAuthToken,
} from "../../redux/auth/actions";
// keep getCustomerInfo until ready for user migration
import {
  getCustomerInfo,
  getCustomerInfoSuccess,
} from "../../redux/customer/actions";
import { getUserGuid } from "../../utils/auth0";
import { ConstantKey } from "../../constants";
import { URL } from "../../constants/url";
import * as Api from "../../helper/api";
import { Carousel } from "react-bootstrap";
import Carousel1 from "../../assets/img/this-is-financial-communications-4.png";
import Carousel2 from "../../assets/img/reward-per-action-2.png";
import Carousel3 from "../../assets/img/cash-out-your-way-3.png";
import Carousel4 from "../../assets/img/respond-and-get-rewarded-1.png";

const LoadingBar = () => (
  <div className="page-content">
    <PuffLoader color="#3f80ea" />
    <span>Logging in....</span>
  </div>
);

const SignInSuccess = () => {
  console.log("SignInSuccess JS app");
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const authState = useSelector((state) => state.Auth);
  const [isReadingFinished, setReadingFinished] = useState(false);
  const [isUserInfoFetched, setUserInfo] = useState();
  const [auth0Id, setAuth0Id] = useState();

  // OLD - but can't be removed yet or logins don't work
  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      if (token) {
        dispatch(setAuthToken(token));
        localStorage.setItem(ConstantKey.AUTH_TOKEN, token);
        const auth0Id = getUserGuid(user.sub);
        setAuth0Id(auth0Id);
        dispatch(getCustomerInfo(auth0Id));
        dispatch(loginUserSuccess(user));
      }
    }

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated]);

  //NEW
  const fetchUserInfo = async () => {
    try {
      let response = await Api.getDataSecure(
        URL.getUserInfo(auth0Id),
        authState.token
      );
      dispatch(getCustomerInfoSuccess(response.data));
      dispatch(userPreference(authState.userId));
      dispatch(userPreference(auth0Id));
      setUserInfo(response.data);

      // console.log("response data is " + JSON.stringify(response.data));
      if (response.data.emailVerified) {
        console.log("email is verified");
        if (auth0Id) navigate(state.pathname || "/dashboard");
      } else {
        console.log("email is not verified yet");
      }
    } catch (error) {
      console.log("found error of " + error);
      setUserInfo(null);
    }
  };

  //NEW
  useEffect(() => {
    if (user && user.sub && auth0Id) {
      if (!isUserInfoFetched) {
        fetchUserInfo();
      }
    }
    return () => {
      setAuth0Id();
    };
  }, [state, navigate, auth0Id, isUserInfoFetched, authState.userId]);

  /**
   * Controls the carousel status
   */
  useEffect(() => {
    if (isReadingFinished) {
      navigate("/dashboard");
    }
  }, [isReadingFinished]);

  const carouselUI = () => {
    return (
      <div className="carousel_container">
        <Carousel
          interval={8000} //8 second intervals. Help those who don't see arrow buttons
          //changed next arrow to obvious words. So people don't get stuck (user feedback)
          nextIcon={
            <span
              style={{
                fontSize: `80px`,
                color: "#FF5A5F",
                backgroundColor: "#FFFFFF",
                border: "solid 2px black",
                display: "inline-block",
                height: ".75em",
                lineHeight: ".75em",
              }}
            >
              >
            </span>
          }
          indicators={false}
          wrap={false} // prevents back button issues of 1st slide -> home page
          keyboard={false} // Slow user for ~30 seconds while SQS registers account
          onSelect={(e) => {
            if (e === 4) {
              setReadingFinished(true);
            }
          }}
        >
          <Carousel.Item>
            <div>
              <h1 className="text-center">Respond and Get Rewarded</h1>
              <p style={{ fontSize: 20 }}>
                Any inbound emails, surveys, calls, or texts you get from a
                FynCom partner <b> are designed to instantly reward you.</b> It
                is that simple. This is communications, reimagined.
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel1}
                alt="World map on 2d plane with wavy transparent sheet in front of the map"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <h1 className="text-center">Rewards per Action</h1>
              <p style={{ fontSize: 20 }}>
                <b>Translate a Word, Get a Reward.</b> An example of the unique
                tech we'll make. Try it out today by going into your settings
                and looking for "Crowdin". Have fun!
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel2}
                alt="City skyline night illuminated by a faint overlaid globe in the background with nodal architecture"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <h1 className="text-center">Cash Out Your Way</h1>
              <p style={{ fontSize: 20 }}>
                <b>Your Reward. Your decision.</b> Our partners choose “how
                much” and “when” to reward you. We make sure you can cash out
                your way. From gift cards, to cash and more.
              </p>
            </div>
            <div>
              {" "}
              <img
                className="d-block w-100"
                src={Carousel3}
                alt="Curvature with 3D asymptopical curvature"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <h1 className="text-center">
                Not Your Grandpa’s Loyalty Program
              </h1>
              <p style={{ fontSize: 20 }}>
                This is next-gen rewards tech, built to improve the way society
                communicates online. <b>FynCom is Financial Communications. </b>
                Feel the magic of instant rewards!
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel4}
                alt="interconnected nodes"
              />
            </div>
          </Carousel.Item>
          {/* Note: This carousel item below is not actually viewed. It is immediately skipped. */}
          <Carousel.Item>
            <div>
              <h1>Marketing & product feedback teams use this the most.</h1>
              <p style={{ fontSize: 20 }}>
                <b>Scaleable Rewards.</b> Want to A/B test multiple rewards in
                parallel across marketing and single emails? Our integrations
                let you manage multiple campaigns for next-level marketing.
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel4}
                alt="ending slide"
              />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  };

  return (
    <div>
      {isUserInfoFetched === undefined ? (
        <LoadingBar />
      ) : isUserInfoFetched !== undefined &&
        isUserInfoFetched !== null &&
        isUserInfoFetched.emailVerified ? (
        <LoadingBar />
      ) : (
        carouselUI()
      )}
    </div>
  );
};

export default SignInSuccess;
