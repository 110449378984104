import { MessageSquare } from "react-feather";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startTutor, stopTutor } from "../../redux/tutor/actions";
import { useNavigate } from "react-router-dom";
import { setStepCount, startDev, stopDev } from "../../redux/tutor/actions";
import { X } from "react-feather";

function TutorModule() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tutorState = useSelector((state) => state.Tutor);
  const totalStep = 1;

  useEffect(() => {
    if (tutorState.isActive) {
      switch (tutorState.tutorStep) {
        case 1:
          step1();
          break;
        default:
          break;
      }
    }
  }, [tutorState.tutorStep]);

  useEffect(() => {
    window.addEventListener(
      "keydown",
      function (event) {
        if (event.code === "KeyF" && event.shiftKey && event.ctrlKey) {
          if (!tutorState.dev) {
            dispatch(startDev());
            navigate("/");
          } else {
            dispatch(stopDev());
            navigate("/");
          }
        }
      },
      true
    );
  }, []);

  const step1 = () => {
    if (document.getElementById("tutor_instruction_id")) {
      document.getElementById("tutor_instruction_id").remove();
    }
    navigate("/dashboard");
    let mainComponent = document.getElementById("main");
    if (mainComponent) {
      mainComponent.classList.add("tutor_main_css");
    }

    let popoverElement = document.createElement("div");
    popoverElement.setAttribute("id", "tutor_instruction_id");
    popoverElement.innerHTML = instructionPanel("balance-note");
    setExitClickHandler();
    let balanceSummaryElement = document.getElementById("balance-summary");
    balanceSummaryElement.classList.add("total-bal-card-css");
    balanceSummaryElement.appendChild(popoverElement);
  };

  const handleswitch = () => {
    dispatch(startTutor());
    dispatch(setStepCount(1));
    navigate("/");
  };

  const setExitClickHandler = () => {
    let exitTimer = setTimeout(() => {
      if (document.getElementById("exitDemo")) {
        document.getElementById("exitDemo").addEventListener("click", exitDemo);
        clearTimeout(exitTimer);
      }
    }, 1000);
  };

  const instructionPanel = (outerElementStyle, stepValue = undefined) => {
    let step = { header: "", content: "" };
    let arrowCss = "tutor-arrow";
    let arrowVisibility = "";
    switch (tutorState.tutorStep) {
      case 1:
        step.header = "Available Balance";
        step.content =
          "Welcome To Fyncom! Here is the balance you've earned from interacting with Fyncom partner.";
        arrowCss = "total-bal-pointer";
        break;
      default:
        break;
    }
    return `<div class="${outerElementStyle}">
 
    <div class="card border-success mb-3 instruction-card">
    <div class="${arrowCss}">
    </div>
    <div class="card-header bg-transparent border-success">
      <div class="d-inline ">
        <div
          class="d-inline"
        >
        <i class="fa-solid fa fa-chevron-left px-2 ${arrowVisibility}" id="tutor_left_nav" ></i>
        </div>
        <div class="d-inline">
        <i class="fa-solid fa fa-chevron-right px-2 ${arrowVisibility}" id="tutor_right_nav"></i>
        </div>
      </div>
      <div class="tutor-counter">
        ${tutorState.tutorStep}/${totalStep}	
      </div>
    </div>
    <div class="card-body text-success">
      <h5 class="card-title">${step.header}</h5>
      <p class="card-text tutor-text">${step.content}</p>
    </div>
    <div class="card-footer bg-transparent border-success">
      <div
        id="exitDemo"
        class="d-inline"
      >
        skip
      </div>
    </div>
  </div>
  
  </div>`;
  };

  const exitDemo = () => {
    document.querySelector(".content").classList.remove("tutor-card-fade");
    if (document.getElementById("tutor_instruction_id")) {
      document.getElementById("tutor_instruction_id").remove();
    }
    dispatch(stopTutor());
    setShow(false);
    navigate("/");
  };

  return (
    <React.Fragment>
      <div>
        {show && !tutorState.isActive ? (
          <div className="tutor-widget-position">
            <div className="tutor-widget-outer">
              <div className="tutor-widget-head">
                <div className="brand-logo-str">Fyncom</div>
              </div>
              <div className="tutor-widget-body bg-white">
                <div className="message_text_outer">
                  <div className="message_content">Hi there!</div>
                </div>
                <div className="message_text_outer">
                  <div className="message_content">
                    What brings you here today?
                  </div>
                </div>
                {/* available options */}
                <div>
                  <div className="tutor-message-options">
                    <div
                      className="tutor-message-option"
                      onClick={() => handleswitch()}
                    >
                      App Demo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {tutorState.dev ? (
          <div className="tutor-icon-position">
            <div className="message-icon-position">
              <div
                className={show ? "" : "d-none"}
                onClick={() => {
                  setShow(!show);
                }}
              >
                <X className="message-icon-css" />
              </div>

              <div
                className={!show && !tutorState.isActive ? "" : "d-none"}
                onClick={() => {
                  setShow(!show);
                }}
              >
                <MessageSquare className="message-icon-css" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

export default TutorModule;
