import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { URL } from "../../constants/url";
import * as Api from "../../helper/api";
import { useSelector } from "react-redux";
import { Copy } from "react-feather";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte

function Referrals() {
  const authState = useSelector((state) => state.Auth);
  const balanceState = useSelector((state) => state.Balance);
  const [referalCode, setReferalCode] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    types: [
      {
        type: "info",
        background: "blue",
        icon: false,
      },
    ],
  });

  useEffect(() => {
    async function getRefferal() {
      try {
        let token = authState.token;
        let userId = balanceState.userId;
        let response = await Api.postDataSecureOldVersion(
          URL.getReferalCode(),
          token,
          {
            userId: userId,
          }
        );

        setReferalCode(response.referralCode);
      } catch (error) {
        console.log(error);
      }
    }
    getRefferal();
  }, [authState.token, balanceState.userId]);

  const handleCopyClick = async (e) => {
    e.preventDefault();
    await navigator.clipboard.writeText(
      `https://www.fyncom.com/referrals?code=${referalCode}`
    );
    notyf.open({ type: "info", message: "copied to clipboard" });
    setIsCopied(true);
  };

  return (
    <div>
      <Helmet title="Referral" />
      <pageTitle title="Referral" breadCrumbItems={[]} />

      <Card className="py-1 pl-2">
        <Card.Body>
          <Row>
            <Col>
              <h1>Referrals</h1>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="my-4">
        <Card.Body>
          <div>
            <h1>Your Referral Code</h1>
            <div className="referal_code_box">{referalCode}</div>
          </div>

          <div className="my-5">
            <h1>Share this link</h1>
            <div
              className={`copy-link-css ${isCopied ? "copied" : ""}`}
              onClick={handleCopyClick}
            >
              <span>{`https://www.fyncom.com/referrals?code=${referalCode}`}</span>
              <Copy className="float-end" />
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Referrals;
