import produce from "immer";
import { NotificationTypes } from "./types";

const initialState = {};

export const Notification = (state = initialState, action) => {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case NotificationTypes.SET_NOTIFICATION:
        const { page, type, message } = action.payload;
        draftState[page] = { type, message };
        break;
      default:
        break;
    }
  });
  return nextState;
};
