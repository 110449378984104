import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import { URL } from "../../constants/url";
import { getDataSecure } from "../../helper/api";
import {
  getUserInfo,
  getUserInfoFailed404,
  getUserInfoSuccess,
} from "./actions";
import { UserTypes } from "./types";
import { setNotification } from "../notification/actions";
import { getRandomBetweenRange } from "../../utils/common";

/**
 * NOTE: This one is not really used right now. Even this this repo is for Users...
 * We are using the Customer Folder for the most part. Please be aware of this.
 * Anything you might expect for "User" are most likely being performed to "Customer" redux folder
 */
function* getUserInfoSaga(action) {
  const { auth0Id, remainingRetrial } = action.payload;

  try {
    let userInfoResponse = yield call(getDataSecure, URL.getUserInfo(auth0Id));
    yield put(getUserInfoSuccess(userInfoResponse.data));

    // if customer doesn't have organization, call api to create organization
    // NOTE: This is not needed, but There may be other user "Activations" you want to consider
    // if (customerInfoResponse.organizations.length === 0) {
    //   const createOrgResponse = yield call(
    //     postData,
    //     ApiRoutes.createOrganizationRoute(),
    //     { customerId: customerInfoResponse.customerId }
    //   );
    //   yield put(createOrganizationSuccess(createOrgResponse));
    // } else {
    //   yield put(getUserInfoSuccess(customerInfoResponse));
    // }
  } catch (error) {
    // TODO: Verify that 404 is the correct status to look for here.
    if (error.status === 404) {
      yield put(getUserInfoFailed404(auth0Id, remainingRetrial - 1));
    } else {
      yield put(
        setNotification("accountSetup", "error", "Unable to retrieve user info")
      );
    }
  }
}

function* getUserInfoFailed404Saga(action) {
  const { auth0Id, remainingRetrial } = action.payload;
  if (remainingRetrial > 0) {
    // delay the next call for 8-15s
    const milliseconds = getRandomBetweenRange(8, 15) * 1000;
    yield delay(milliseconds);
    yield put(getUserInfo(auth0Id, remainingRetrial));
  } else {
    yield put(
      setNotification("accountSetup", "error", "Unable to retrieve user info")
    );
  }
}

function* watchGetAuthInfo() {}

function* watchGetUserInfo() {
  yield takeEvery(UserTypes.GET_INFO, getUserInfoSaga);
}

function* watchGetUserInfoFailed404() {
  yield takeEvery(UserTypes.GET_INFO_FAILED_404, getUserInfoFailed404Saga);
}

function* Auth() {
  console.log("userAuthSaga was activated");
  yield all([
    fork(watchGetAuthInfo),
    fork(watchGetUserInfo),
    fork(watchGetUserInfoFailed404),
  ]);
}

export default Auth;
