import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, ListGroup } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import UserProfile from "./profile";
import Crowdin from "./crowdin";
import EmailFilter from "./filter/email";
import EmailFilterTest from "./filter/emailTest";

function Settings() {
  const [selectIndex, setNavIndex] = useState("1");
  const [kubikDataFlag, setKubikDataFlag] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (
        event.code === "KeyK" &&
        event.shiftKey &&
        event.ctrlKey &&
        event.altKey
      ) {
        setKubikDataFlag(!kubikDataFlag);
      }
    });
  }, [kubikDataFlag]);

  const Navigation = () => (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === "1"}
          onClick={() => {
            setNavIndex("1");
          }}
        >
          Profile
        </ListGroup.Item>

        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === "2"}
          onClick={() => {
            setNavIndex("2");
          }}
        >
          Crowdin
        </ListGroup.Item>

        {/* {kubikDataFlag ? ( */}
        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === "3"}
          onClick={() => {
            setNavIndex("3");
          }}
        >
          Email Filter
        </ListGroup.Item>
        {/* ) : null} */}

        {kubikDataFlag ? (
          <ListGroup.Item
            tag="button"
            action
            active={selectIndex && selectIndex === "3"}
            onClick={() => {
              setNavIndex("4");
            }}
          >
            Email Filter Next
          </ListGroup.Item>
        ) : null}
      </ListGroup>
    </Card>
  );
  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <Card className="py-1 pl-2">
          <Card.Body>
            <Row>
              <div className="col-6">
                <h1>Settings</h1>
              </div>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            {selectIndex === "1" ? <UserProfile /> : ""}
            {selectIndex === "2" ? <Crowdin /> : ""}
            {selectIndex === "3" ? <EmailFilter /> : ""}
            {selectIndex === "4" ? <EmailFilterTest /> : ""}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Settings;
