import React, { useContext, useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useAuth0 } from "@auth0/auth0-react";
import {
  handleBaseScopes,
  handleModifyScope,
  handleSendScope,
} from "../../../utils/auth0";

/** dark / light buttons are hard. do dark */
function EmailFilterTest() {
  const { loginWithRedirect } = useAuth0();

  return (
    <React.Fragment>
      <Helmet title="Email Filter" />
      <Container fluid className="p-0 my-3">
        <Card>
          <Card.Body>
            <div>
              <button onClick={() => handleBaseScopes(loginWithRedirect)}>
                First Scopes
              </button>
            </div>
            <div>
              <button onClick={() => handleModifyScope(loginWithRedirect)}>
                Modify Scopes
              </button>
            </div>
            <div>
              <button onClick={() => handleSendScope(loginWithRedirect)}>
                Send Scopes
              </button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default EmailFilterTest;
