import React from "react";
import { Row, Card } from "react-bootstrap";

const Header = () => {
  return (
    <Card className="py-1 pl-2">
      <Card.Body>
        <Row>
          {/* <div className="col-12"> */}
          <h1>App Dashboard</h1>
          {/* </div> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Header;
