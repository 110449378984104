import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { URL } from "../../constants/url";
import { getDataSecure } from "../../helper/api";
import { fetchAmount, setAmount } from "../balance/action";
import { BalanceType } from "../balance/types";
import { getUserGuid } from "../../utils/auth0";

function* fetchCurrentBalance() {
  const state = yield select();
  const token = state.Auth.token;
  const user = state.Auth.user;
  const auth0Id = state.Customer.info.authId;
  // const auth0Id = state.Customer.auth0Id; // desired call - need backend change
  try {
    if (auth0Id === undefined) {
      getUserGuid(user.sub);
    }
    let response = yield call(getDataSecure, URL.getBalance(auth0Id), token);
    if (response.status === 200) {
      yield put(
        setAmount({
          usd: response.data.accountBalanceInUSD,
          nano: response.data.accountBalanceInNano,
          accountAddress: response.data.accountAddress,
          userId: response.data.userId,
          rate: response.data.rate,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Deprecate - I don't think this is needed - doesn't seem to be used anywhere either....
 */
export function* fetchCurrentBalance1(token, userid) {
  try {
    let response = yield call(getDataSecure, URL.getBalance(userid), token);
    yield put(fetchAmount(response));
  } catch (error) {}
}

function* watchBalanceInfo() {
  yield takeEvery(BalanceType.FETCH_CURRENT_BALANCE, fetchCurrentBalance);
}

function* Balance() {
  yield all([fork(watchBalanceInfo)]);
}

export default Balance;
