import {
  all,
  call,
  delay,
  fork,
  put,
  // eslint-disable-next-line prettier/prettier
  takeEvery,
} from "redux-saga/effects";
import { getRandomBetweenRange } from "../../utils/common";
import { setNotification } from "../notification/actions";
import {
  getCustomerInfo,
  getCustomerInfoFailed404,
  getCustomerInfoSuccess,
} from "./actions";
import { CustomerTypes } from "./types";
import { URL } from "../../constants/url";
import { getDataSecure } from "../../helper/api";

function* getCustomerInfoSaga(action) {
  const { auth0Id, remainingRetrial } = action.payload;

  try {
    let customerInfoResponse = yield call(
      getDataSecure,
      URL.getUserInfo(auth0Id)
    );
    yield put(getCustomerInfoSuccess(customerInfoResponse.data));
  } catch (error) {
    if (error.status === 404) {
      yield put(getCustomerInfoFailed404(auth0Id, remainingRetrial - 1));
    } else {
      yield put(
        setNotification("accountSetup", "error", "Unable to retrieve user info")
      );
    }
  }
}

function* getCustomerInfoFailed404Saga(action) {
  const { auth0Id, remainingRetrial } = action.payload;
  if (remainingRetrial > 0) {
    // delay the next call for 8-15s
    const milliseconds = getRandomBetweenRange(8, 15) * 1000;
    yield delay(milliseconds);
    yield put(getCustomerInfo(auth0Id, remainingRetrial));
  } else {
    yield put(
      setNotification(
        "accountSetup",
        "error",
        "Unable to retrieve customer info"
      )
    );
  }
}

function* watchGetCustomerInfo() {
  yield takeEvery(CustomerTypes.GET_INFO, getCustomerInfoSaga);
}

function* watchGetCustomerInfoFailed404() {
  yield takeEvery(
    CustomerTypes.GET_INFO_FAILED_404,
    getCustomerInfoFailed404Saga
  );
}

function* customerSaga() {
  yield all([fork(watchGetCustomerInfo), fork(watchGetCustomerInfoFailed404)]);
}

export default customerSaga;
