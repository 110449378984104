import { CustomerTypes } from "./types";
// should change to using user later... for now, this is the only thing that works
export const getCustomerInfo = (auth0Id, remainingRetrial) => {
  if (remainingRetrial === undefined) remainingRetrial = 5;

  return {
    type: CustomerTypes.GET_INFO,
    payload: { auth0Id, remainingRetrial },
  };
};

export const getCustomerInfoSuccess = (customerInfo) => {
  return {
    type: CustomerTypes.GET_INFO_SUCCESS,
    payload: { customerInfo },
  };
};

export const getCustomerInfoFailed404 = (auth0Id, remainingRetrial) => {
  return {
    type: CustomerTypes.GET_INFO_FAILED_404,
    payload: { auth0Id, remainingRetrial },
  };
};

export const clearCustomer = () => {
  return {
    type: CustomerTypes.CLEAR_CUSTOMER,
  };
};
