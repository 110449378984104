import { UserTypes } from "./types";

/**
 * (AEG): As far as I know, this is not actively used - we have no user info API yet
 */
export const getUserInfo = (auth0Id, remainingRetrial) => {
  if (remainingRetrial === undefined) remainingRetrial = 5;
  return {
    type: UserTypes.GET_INFO,
    payload: { auth0Id, remainingRetrial },
  };
};

export const getUserInfoSuccess = (userInfo) => {
  return {
    type: UserTypes.GET_INFO_SUCCESS,
    payload: { userInfo },
  };
};

export const getUserInfoFailed404 = (auth0Id, remainingRetrial) => {
  return {
    type: UserTypes.GET_INFO_FAILED_404,
    payload: { auth0Id, remainingRetrial },
  };
};

export const setAuthToken = (state) => {
  return {
    type: UserTypes.SET_AUTH_TOKEN,
    payload: state,
  };
};

export const clearUser = () => {
  return {
    type: UserTypes.CLEAR_USER,
  };
};
