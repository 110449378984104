import { AuthTypes } from "./types";

export const setAuthToken = (state) => {
  return {
    type: "SET_AUTH_TOKEN",
    payload: state,
  };
};

export const setAuth0Id = (state) => {
  return {
    type: "SET_AUTH0_ID",
    payload: state,
  };
};

export const loginUserSuccess = (user) => {
  return {
    type: AuthTypes.LOGIN_SUCCESS,
    payload: user,
  };
};

export const userPreference = (pref) => {
  return {
    type: AuthTypes.PREF,
    payload: pref,
  };
};

export const logoutUser = () => {
  return {
    type: AuthTypes.LOGOUT,
  };
};
