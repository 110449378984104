import produce from "immer";
import { BalanceType } from "./types";

const initialState = {
  usd: 0,
  nano: 0,
  accountAddress: "",
  userId: 0,
  rate: 0,
};

export const Balance = (state = initialState, action) => {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case BalanceType.SET_AMOUNT:
        draftState.usd = action.payload.usd;
        draftState.nano = action.payload.nano;
        draftState.accountAddress = action.payload.accountAddress;
        draftState.userId = action.payload.userId;
        draftState.rate = action.payload.rate;
        break;
      case BalanceType.FETCH_CURRENT_BALANCE:
        draftState = state;
        break;
      default:
        break;
    }
  });
  return nextState;
};
