import { useAuth0 } from "@auth0/auth0-react";
import { default as React, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SignInPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("logging in signin js"); // 2nd thing
    if (!isAuthenticated && !isLoading) {
      console.log("if loop with "); // 3rd thing - sign in after here
      // consider updating here with default scope null for advanced options
      loginWithRedirect({
        appState: state,
        scope: "offline_access", // Add the offline_access scope here - refresh tokens
        fragment: "from-SignIn-JS",
      });
    } else if (isAuthenticated && user) {
      console.log("in else loop of sign in");
      let redirectpath = "/dashboard";
      if (state !== undefined && state.pathname !== undefined)
        redirectpath = state.pathname;

      navigate(redirectpath);
    }
  }, [isAuthenticated, loginWithRedirect, user, state, isLoading, navigate]);

  return <></>;
};

export default SignInPage;
