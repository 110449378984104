import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import DataTable from "../../components/datatable/index";
import { URL } from "../../constants/url";
import * as Api from "../../helper/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function GiftCard() {
  // const fullState = useSelector((state) => state);
  const authState = useSelector((state) => state.Auth);
  const customerState = useSelector((state) => state.Customer);
  const [trasnsactions, setGiftTransactionList] = useState([]);
  const navigate = useNavigate();

  const formatedDate = (date) => {
    return new Date(date.toString()).toLocaleDateString().split("/").join("-");
  };

  /**
   * Here, it seems like the customer state is properly set & "loaded", so we can use this.
   */
  useEffect(() => {
    async function getGiftCardTransactionHistory() {
      // console.log("full gift card state is " + JSON.stringify(fullState));
      let token = authState.token;
      let auth0Id = customerState.info.authId;
      // let auth0Id = customerState.info.auth0Id; // preferred usage - need backend update
      let response = await Api.getDataSecure(
        URL.getGiftCardHistory(auth0Id),
        token
      );
      if (response.status === 200) {
        let tempData = [];
        response.data.giftCardTransactions.forEach((e) => {
          tempData.push({
            productName: e.productName,
            transactionStatus: e.transactionStatus,
            amount: e.amount,
            createDate: formatedDate(e.createDate),
          });
        });
        setGiftTransactionList(tempData);
      } else if (response.status === 401) {
        navigate("/auth/signout");
      }
    }

    getGiftCardTransactionHistory();
  }, [authState.token, authState.userId]); //may need to update

  const initialSort = [
    {
      id: "productName",
      desc: true,
    },
  ];
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "status",
        accessor: "transactionStatus",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Create Date",
        accessor: "createDate",
      },
    ];
  });

  return (
    <React.Fragment>
      <Helmet title="Gift Card" />
      <Card className="py-1 pl-2">
        <Card.Body>
          <Row>
            <div className="col-6">
              <h1>Gift Cards</h1>
            </div>
          </Row>
          <Row>
            <div className="justify-content-end">
              <Link to="/gift/redeem" className="">
                <Button variant="primary">View Available Gift Cards</Button>
              </Link>
            </div>
          </Row>
        </Card.Body>
      </Card>
      <Container fluid className="p-0">
        <Card>
          <Card.Body>
            {trasnsactions && trasnsactions.length > 0 ? (
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={trasnsactions}
                  sortBy={initialSort}
                />
              </div>
            ) : (
              <Alert variant="primary">
                <div className="alert-message">No data available to show</div>
              </Alert>
            )}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default GiftCard;
