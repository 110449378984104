import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { Navigate } from "react-router-dom";
import { ConstantKey } from "../../constants";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  let authToken = window.localStorage.getItem(ConstantKey.AUTH_TOKEN);
  if (!authToken) {
    if (!isAuthenticated) {
      return <Navigate to="/auth/sign-in" />;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
