import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import { postDataSecure } from "../../../helper/api";
import { fetchAmount } from "../../../redux/balance/action";
import { useSelector } from "react-redux";

const SendNanoForm = () => {
  const authState = useSelector((state) => state.Auth);
  const notyf = useContext(NotyfContext);
  const [nanoAddress, setNanoAddress] = React.useState("");
  const [amount, setAmount] = React.useState(localStorage.amount || 0);
  const dispatch = useDispatch();
  const customerState = useSelector((state) => state.Customer);
  const balance = useSelector((state) => state.Balance);
  const [nanoAmount, setNanoAmount] = React.useState(null);
  const [usdAmount, setUsdAmount] = React.useState(null);
  const [isMax, setIsMax] = React.useState(false);

  // tempting to just use the target value, but React State is not updated immediately.
  const handleNanoChange = (e) => {
    let newNanoAmount = e.target.value;
    if (newNanoAmount === ".") {
      newNanoAmount = "0.";
    }
    if (newNanoAmount.length === 0 || !isNaN(newNanoAmount)) {
      setNanoAmount(newNanoAmount);
      if (newNanoAmount.length === 0) {
        setUsdAmount("");
      } else {
        setUsdAmount(newNanoAmount * balance.rate);
        setIsMax(false);
      }
    }
  };

  const handleUsdChange = (e) => {
    let newUsdAmount = e.target.value;
    if (newUsdAmount === ".") {
      newUsdAmount = "0.";
    }
    if (newUsdAmount.length === 0 || !isNaN(newUsdAmount)) {
      setUsdAmount(newUsdAmount);
      if (newUsdAmount.length === 0) {
        setNanoAmount("");
      } else {
        setNanoAmount(newUsdAmount / balance.rate);
        setIsMax(false);
      }
    }
  };

  useEffect(() => {
    if (localStorage.nano_address !== "null") {
      setNanoAddress(localStorage.nano_address);
    }
  }, []);

  const useSendNano = async () => {
    try {
      let token = authState.token;
      let userId = customerState.info.authId;
      await postDataSecure("/nano/user/send", token, {
        amount: amount,
        auth0Id: userId,
        destinationAddress: nanoAddress,
        sendMax: isMax,
      });
      dispatch(fetchAmount());
      notyf.open({
        type: "success",
        message: "Your nano transaction was successful.",
      });
    } catch (error) {
      notyf.open({
        type: "error",
        message: "Unable to send nano.",
      });
    }
  };

  const handleMaxClick = async () => {
    try {
      setNanoAmount(balance.nano);
      setUsdAmount(balance.usd);
      setIsMax(true);
    } catch (error) {
      notyf.open({
        type: "error",
        message: "Unable to set max amount.",
      });
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <span className="card-title">Send Nano</span>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="9">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>To nano address</Form.Label>
                  <Form.Control
                    type="text"
                    value={nanoAddress}
                    onChange={(e) => setNanoAddress(e.target.value)}
                    placeholder="Enter nano address to send to"
                    style={{
                      width: "100%",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="nanoAmount">
                  <Form.Label>Nano amount to send</Form.Label>
                  <div style={{ position: "relative", width: "75%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 5 }}>Ӿ</span>{" "}
                      <Form.Control
                        type="text"
                        value={nanoAmount}
                        placeholder="Amount of nano"
                        onChange={handleNanoChange}
                        style={{
                          "-webkit-appearance": "none",
                          "-moz-appearance": "textfield",
                        }}
                      />
                    </div>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Set maximum amount
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        onClick={handleMaxClick}
                      >
                        MAX
                      </Button>
                    </OverlayTrigger>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="usdAmount">
                  <Form.Label>USD amount to send</Form.Label>
                  <div style={{ position: "relative", width: "75%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 5 }}>$</span>{" "}
                      <Form.Control
                        type="text"
                        value={usdAmount}
                        placeholder="Amount in USD"
                        onChange={handleUsdChange}
                        style={{
                          "-webkit-appearance": "none",
                          "-moz-appearance": "textfield",
                        }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col md="3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    onClick={useSendNano}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SendNanoForm;
