import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("in landing");
    if (!isAuthenticated && !isLoading) {
      console.log("not authenticated - requires sign in");
      navigate("/auth/sign-in");
    } else if (isAuthenticated) {
      console.log("landing is authenticated");
      navigate("/dashboard");
    }
  }, [isAuthenticated, isLoading, navigate]);

  return <>Loading...</>;
};

export default Landing;
