import { toast, Zoom } from "react-toastify";

/** Notification related constants */
export const TOAST_OPTIONS = {
  position: toast.POSITION.TOP_CENTER,
  transition: Zoom,
};

export const GMAIL_LABELS_SCOPE =
  "https://www.googleapis.com/auth/gmail.labels";
export const GMAIL_METADATA_SCOPE =
  "https://www.googleapis.com/auth/gmail.metadata";
export const GMAIL_SETTINGS_BASIC_SCOPE =
  "https://www.googleapis.com/auth/gmail.settings.basic";

export const GMAIL_SEND_SCOPE = "https://www.googleapis.com/auth/gmail.send";
export const GMAIL_MODIFY_SCOPE =
  "https://www.googleapis.com/auth/gmail.modify";
// prefer not to use this scope. It was used for Alpha testing
export const GMAIL_READ_SCOPE =
  "https://www.googleapis.com/auth/gmail.readonly";
