import { TutorType } from "./types";

export const startTutor = () => {
  return {
    type: TutorType.SWITCH_TUTOR,
    payload: true,
  };
};

export const stopTutor = () => {
  return {
    type: TutorType.SWITCH_TUTOR,
    payload: false,
  };
};

export const setRefs = (state) => {
  return {
    type: TutorType.COMPONENT_REFS,
    payload: state,
  };
};

export const setStepCount = (state) => {
  return {
    type: TutorType.SET_STEP,
    payload: state,
  };
};

export const startDev = () => {
  return {
    type: TutorType.START_DEV,
    payload: true,
  };
};

export const stopDev = () => {
  return {
    type: TutorType.STOP_DEV,
    payload: false,
  };
};
