import {
  GMAIL_LABELS_SCOPE,
  GMAIL_METADATA_SCOPE,
  GMAIL_SETTINGS_BASIC_SCOPE,
  GMAIL_SEND_SCOPE,
  GMAIL_MODIFY_SCOPE,
  GMAIL_READ_SCOPE,
} from "../constants/common";

const getUserGuid = (userSub) => {
  const idParts = userSub.split("|");
  return idParts[1];
};

const isUserAuthenticated = (authState) => {
  return authState.isAuthenticated || false;
};

const getBaseScopes = () => {
  const baseScopes = `${GMAIL_LABELS_SCOPE} ${GMAIL_METADATA_SCOPE} ${GMAIL_SETTINGS_BASIC_SCOPE}`;
  return baseScopes;
};

// common methods for handling scopes
const handleScopes = async (loginWithRedirect, scopes, logMessage) => {
  console.log(logMessage);
  loginWithRedirect({
    access_type: "offline",
    connection_scope: getBaseScopes() + (scopes ? " " + scopes.join(" ") : ""),
    connection: "google-oauth2",
    fragment: "HANDLE-GMAIL-OPT-IN",
  });
};

// minimum required scopes to handle payouts logic. 1 of the 2 after this is HIGHLY PREFERRED
const handleBaseScopes = (loginWithRedirect) =>
  handleScopes(loginWithRedirect, null, "handling base scopes");

// I HIGHLY prefer this one for the label management, but its read emails permission is too broad
const handleModifyScope = (loginWithRedirect) =>
  handleScopes(
    loginWithRedirect,
    [GMAIL_MODIFY_SCOPE],
    "handling base and modify SCOPES"
  );

// this is the fairest combination scope. It allows us to send PayCation emails, but not read inbox
// we won't be able to apply labels here.... only create them.
const handleSendScope = (loginWithRedirect) =>
  handleScopes(
    loginWithRedirect,
    [GMAIL_SEND_SCOPE],
    "handling base and send SCOPES"
  );

// ideally this is never used again. It was used for Alpha testing
const handleFullScopes = (loginWithRedirect) =>
  handleScopes(
    loginWithRedirect,
    [GMAIL_MODIFY_SCOPE, GMAIL_SEND_SCOPE, GMAIL_READ_SCOPE],
    "handling full scopes"
  );

export {
  getUserGuid,
  isUserAuthenticated,
  getBaseScopes,
  handleFullScopes,
  handleBaseScopes,
  handleModifyScope,
  handleSendScope,
};
