import { BalanceType } from "../balance/types";

export const setAmount = (state) => {
  return {
    type: BalanceType.SET_AMOUNT,
    payload: state,
  };
};

export const fetchAmount = () => {
  return {
    type: BalanceType.FETCH_CURRENT_BALANCE,
  };
};
