import React, { useEffect } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { DollarSign } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { fetchAmount } from "../../../redux/balance/action";
import { useNavigate } from "react-router-dom";

const NanoBalanceWidget = ({ balance, address }) => (
  <>
    <span className="h1 d-inline-block mt-1 mb-3">
      {balance.usd >= 0.01 || balance.usd == 0 || balance.usd < 0.000001
        ? balance.usd.toFixed(2)
        : balance.usd >= 0.0001
        ? balance.usd.toFixed(4)
        : balance.usd.toFixed(6)}
    </span>
    <div>
      <span>
        Equivalent nano balance:&nbsp;
        {balance.usd >= 0.01 || balance.usd == 0 || balance.usd < 0.000001
          ? balance.nano.toFixed(2)
          : balance.usd >= 0.0001
          ? balance.nano.toFixed(5)
          : balance.nano.toFixed(7)}
      </span>
    </div>
    <div>
      <span className="mr-2">My nano address:</span>
      <a
        className="mb-2 nano_address_link"
        href={`https://nanexplorer.com/nano/account/${address}`}
        // href={`https://nanoblockexplorer.com/explorer/account/${address}/history`}
        rel="noopener noreferrer"
        style={{ cursor: "pointer" }}
      >
        {address}
      </a>
    </div>
  </>
);

const Statistics = () => {
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.Balance);
  const userState = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState.isAuthenticated) {
      dispatch(fetchAmount());
    } else {
      navigate("/auth/signout");
    }
  });

  return (
    <Row>
      <Col md="12">
        <Card className="flex-fill position-relative">
          <Card.Body>
            <div id="balance-summary">
              <span className="card-title">Total Balance ($)</span>
              <br></br>
              {balance && balance.usd !== undefined ? (
                <NanoBalanceWidget
                  balance={balance}
                  address={balance.accountAddress}
                />
              ) : (
                <Spinner variant="primary" animation="border" />
              )}
            </div>
            <div
              className="stat stat-sm position-absolute"
              style={{ top: 10, right: 10 }}
            >
              <DollarSign className="align-middle" />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
