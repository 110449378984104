import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Loader from "../../components/Loader";
import { URL } from "../../constants/url";
import NotyfContext from "../../contexts/NotyfContext";
import * as Api from "../../helper/api";

function UserProfile() {
  const [loading, setLoading] = useState(false);
  const notyf = useContext(NotyfContext);
  const userState = useSelector((state) => state.Auth);
  const [userInfo, setUserInfo] = useState();
  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, "Name should have at least one character")
      .required("Required"),
  });
  const customerState = useSelector((state) => state.Customer);

  const handleUpdateUserInfo = async (values, action) => {
    let requestData = {};
    requestData["name"] = values.name;
    if (
      values.phoneNumber !== userInfo.phoneNumber &&
      values.phoneNumber.length > 0
    ) {
      requestData["phoneNumber"] =
        "+" + values.countryCode + values.phoneNumber;
    }

    let auth0Id = customerState.info.authId;
    requestData["auth0Id"] = auth0Id;
    setLoading(true);
    let token = userState.token;
    try {
      await Api.postDataSecure(URL.updateUser, token, requestData);
      notyf.open({ type: "success", message: "Successfully updated" });
      userInfo.phoneNumber = values.phoneNumber;
      setLoading(false);
    } catch (exception) {
      notyf.open({ type: "error", message: "Unable to update information" });
      setLoading(false);
    }
  };

  // Grab and display user's email address
  useEffect(() => {
    async function getInfo() {
      let requestData = {};
      let auth0Id = customerState.info.authId;
      requestData["auth0Id"] = auth0Id;
      try {
        let response = await Api.postDataSecure(
          URL.updateUser,
          userState.token,
          requestData
        );
        setUserInfo(response);
      } catch (exception) {
        notyf.open({ type: "error", message: "Unable to fetch user info" });
      }
    }
    getInfo();
  }, [notyf, userState.token, userState.userId]);

  /**
   * NOTE: You will later want to update the phone numbers here. They only show Auth,
   * Not your DB. Use the customerState.info.phoneNumber
   */
  return (
    <React.Fragment>
      <Helmet title="Profile" />
      <Container fluid className="p-0 my-3">
        <Card>
          <Card.Body>
            {loading ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: userInfo && userInfo.name ? userInfo.name : "",
                  email:
                    userInfo && userInfo.emailAddress
                      ? userInfo.emailAddress
                      : "",
                  phoneNumber:
                    userInfo && userInfo.phoneNumber
                      ? userInfo.phoneNumber
                      : "",
                  countryCode:
                    userInfo && userInfo.countryCode
                      ? userInfo.countryCode
                      : "",
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, action) => {
                  handleUpdateUserInfo(values, action);
                }}
              >
                {(formikProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isValid,
                  } = formikProps;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          id="name"
                          type="text"
                          name="name"
                          isInvalid={touched.name && !!errors.name}
                          value={values.name}
                          placeholder="Enter Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          id="email"
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Enter Email"
                          onBlur={handleBlur}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          id="phoneNumber"
                          type="tel"
                          minLength="10"
                          maxLength="10"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          isInvalid={
                            touched.phoneNumber && !!errors.phoneNumber
                          }
                          placeholder="Enter phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-4"
                        disabled={!isValid}
                      >
                        Submit
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default UserProfile;
