import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Loader from "../../components/Loader";
import { URL } from "../../constants/url";
import NotyfContext from "../../contexts/NotyfContext";
import * as Api from "../../helper/api";

function Crowdin() {
  const [loading, setLoading] = useState(false);
  const notyf = useContext(NotyfContext);
  const userState = useSelector((state) => state.Auth);
  const customerState = useSelector((state) => state.Customer);
  const ValidationSchema = Yup.object().shape({
    crowdinUsername: Yup.string().min(
      1,
      "Crowdin name should have at least one character"
    ),
  });
  const [crowdinUsername, setCrowdinUsername] = useState("");

  const handleUpdateCrowdinInfo = async (values, action) => {
    let requestData = {
      serviceId: crowdinUsername,
      email: values.email,
      service: "CROWDIN",
    };

    setLoading(true);
    let token = userState.token;
    try {
      let response = await Api.postDataSecure(
        URL.updateCrowdin,
        token,
        requestData
      );
      notyf.open({ type: "success", message: "Crowdin user name updated!" });
      setCrowdinUsername(response.serviceId);
      setLoading(false);
    } catch (exception) {
      notyf.open({
        type: "error",
        message: "Unable to update Crowdin user name",
      });
      setLoading(false);
    }
  };

  // Grab and display user's email address and crowdin name
  useEffect(() => {
    async function getInfo() {
      let requestData = {};
      let userId = customerState.info.authId;
      requestData["auth0Id"] = userId;
      try {
        let response = await Api.postDataSecure(
          URL.updateUser,
          userState.token,
          requestData
        );
        setCrowdinUsername(response.crowdinUsername);
      } catch (exception) {
        notyf.open({ type: "error", message: "Unable to fetch user Info." });
      }
    }
    // todo check to make sure it works only run this if email is verified
    if (customerState.info && customerState.info.emailVerified) {
      console.log("email is verified: " + customerState.info.emailVerified);
      getInfo();
    } else {
      console.log("email verified status " + customerState.info.emailVerified);
    }
  }, [notyf, userState.token, userState.userId]);

  return (
    <React.Fragment>
      <Helmet title="Crowdin" />
      <Container fluid className="p-0 my-3">
        {/* <Card className="py-1 pl-2">
          <Card.Body>
            <Row>
              <div className="col-6">
                <h1>Redeem Your Gift Card</h1>
              </div>
            </Row>
          </Card.Body>
        </Card> */}
        <Card>
          <Card.Body>
            <Row>
              <div className="col-12">
                <h4>Our newest product is "Translate a Word, Get a Reward"!</h4>
                <p style={{ fontSize: 14 }}>
                  Thanks to the Nano Foundation for being one of the first to
                  pioneer this tech. They'll instantly reward you when you
                  translate their blog! Take a look at their blog{" "}
                  <a href="https://nano.org/en/blog/the-beginners-guide-to-cryptocurrency-and-nano--652f72a2">
                    The beginners’ guide to cryptocurrency and nano
                  </a>
                  . You can start translating{" "}
                  <a href="https://translations.nano.org/nano-blog">
                    at this link
                  </a>
                  . Come to the user help center for directions on{" "}
                  <a href="https://www.fyncom.com/crowdin-rewards-for-translations-user-setup">
                    setting up your Crowdin integration with FynCom to start
                  </a>
                  . Reach out to us anytime for help -{" "}
                  <a href="mailto: support@fyncom.com?subject=App Dashboard help">
                    Email us
                  </a>{" "}
                  or reach us{" "}
                  <a href="https://twitter.com/Fyn_Com">on Twitter @Fyn_Com</a>
                </p>
              </div>
            </Row>
            {loading ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  crowdinUsername:
                    customerState.info && customerState.info.crowdinUsername
                      ? customerState.info.crowdinUsername
                      : "",
                  email:
                    customerState.info && customerState.info.emailAddress
                      ? customerState.info.emailAddress
                      : "",
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, action) => {
                  handleUpdateCrowdinInfo(values, action);
                }}
              >
                {(formikProps) => {
                  const { values, errors, touched, handleBlur, handleSubmit } =
                    formikProps;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Crowdin User Name</Form.Label>
                        <Form.Control
                          id="crowdinUsername"
                          type="text"
                          name="crowdinUsername"
                          isInvalid={
                            touched.crowdinUsername && !!errors.crowdinUsername
                          }
                          // isInvalid={
                          //   customerState.info &&
                          //   customerState.info.emailVerified
                          //     ? false
                          //     : touched.crowdinUsername &&
                          //       !!errors.crowdinUsername
                          // }
                          // isInvalid={
                          //   firstCampaign === true
                          //     ? false
                          //     : touched.name && !!errors.name
                          // }
                          placeholder="Enter Crowdin User Name"
                          value={crowdinUsername}
                          onChange={(e) => {
                            setCrowdinUsername(e.target.value);
                          }}
                          onBlur={handleBlur}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.crowdinUsername}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          id="email"
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Enter Email"
                          onBlur={handleBlur}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-4"
                        disabled={
                          !(
                            customerState.info &&
                            customerState.info.emailVerified
                          )
                        }
                      >
                        Submit
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default Crowdin;
