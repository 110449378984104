import { combineReducers } from "redux";
import { Auth } from "./auth/reducer";
import { Balance } from "./balance/reducers";
import { Customer } from "./customer/reducers";
import { Notification } from "./notification/reducers";
import { Tutor } from "./tutor/reducers";

export default combineReducers({
  Balance,
  Auth,
  Notification,
  Customer,
  Tutor,
});
