import produce from "immer";
import { AuthTypes } from "./types";

const initialState = {
  isAuthenticated: false,
  user: {},
  token: "",
  userId: "",
};

export const Auth = (state = initialState, action) => {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case AuthTypes.LOGIN_SUCCESS:
        draftState.isAuthenticated = true;
        draftState.user = action.payload;
        break;
      case AuthTypes.LOGOUT:
        draftState.isAuthenticated = false;
        draftState.user = {};
        draftState.token = null;
        draftState.userId = null;
        break;
      case "SET_AUTH_TOKEN":
        draftState.token = action.payload;
        break;
      case "SET_AUTH0_ID":
        draftState.userId = action.payload;
        break;
      default:
        break;
    }
  });
  return nextState;
};
