import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/auth/actions";
import { clearCustomer } from "../../redux/customer/actions";
import { ConstantKey } from "../../constants";

const Signout = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
    dispatch(logoutUser());
    dispatch(clearCustomer());
    localStorage.removeItem(ConstantKey.AUTH_TOKEN);
  });

  return <></>;
};

export default Signout;
