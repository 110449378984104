import { Alert } from "react-bootstrap";
import { RefreshCcw } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerInfo } from "../../redux/customer/actions";

/**
 * This shows a Verify Email UI if the user has not yet verified their email
 * @returns
 */
export const VerifyEmailUI = () => {
  const dispatch = useDispatch();
  const customerState = useSelector((state) => state.Customer);
  // console.log("Check customer state: " + JSON.stringify(customerState));

  return (
    <div>
      {!(customerState && customerState.info.emailVerified) ? (
        <div
          onClick={() => {
            console.log("Check email: " + customerState.info.emailVerified);
            // Note the authId and auth0Id differences - Be Wary when copying from customer app to user app
            dispatch(getCustomerInfo(customerState.info.authId));
          }}
        >
          <div className="row">
            <div className="col-8">
              <Alert variant="warning verify-email-container">
                {/* <div className="alert-message text-center"> */}
                <div className="alert-message text-center verify-text">
                  ***Please verify your email***
                </div>
              </Alert>
            </div>
            <div className="col-4">
              <div className="row refresh-note-container">
                <div className="col-4">
                  <RefreshCcw className="refresh-icon-style" />
                </div>
                <div className="col-8 refresh-note">
                  If you have already verified your email, click here
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
