import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  OverlayTrigger,
  // eslint-disable-next-line prettier/prettier
  Tooltip,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as Api from "../../../helper/api";
import { URL } from "../../../constants/url";
import NotyfContext from "../../../contexts/NotyfContext";
import AmazoneLogo from "../../../assets/img/amazon.png";
import DominosLogo from "../../../assets/img/dominospizza.png";
import DunkinLogo from "../../../assets/img/dunkin.png";
import GrubHub from "../../../assets/img/grubhub.png";
import NoGiftLogo from "../../../assets/img/nogiftcard.jpg";
import StarbuckLogo from "../../../assets/img/starbucks.png";
import CrossImg from "../../../assets/img/cross_icon.svg";
import Loader from "../../../components/Loader";

function WarningModal(props) {
  return (
    <Modal
      show={props.isOpen}
      onHide={props.toggle}
      size="sm"
      dialogClassName="warning-modal"
    >
      <Modal.Header closeButton closeVariant="white">
        <h3 className="text-white">Confirmation</h3>
      </Modal.Header>
      <Modal.Footer>
        <div className="text-white h5">
          Are you sure to redeem ${props.cardValue} for the&nbsp;
          {props.productName} gift card?
        </div>
        <Button variant="secondary" onClick={() => props.toggle(false)}>
          NO
        </Button>
        <Button variant="primary" onClick={() => props.toggle(true)}>
          YES
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Redeem() {
  const [selectedProduct, setProduct] = useState();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isLoading, setLoader] = useState(false);
  const [cardValue, setCardValue] = useState(0);
  const [isPermitted, setPermission] = useState(false);
  const notyf = useContext(NotyfContext);
  const authState = useSelector((state) => state.Auth);
  const customerState = useSelector((state) => state.Customer);
  const [isSuccess, setResponse] = useState(false);
  const [error, setError] = useState("");
  const [activeStep, setAciveStep] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    async function getBalance() {
      let token = authState.token;
      let userId = customerState.info.authId;
      // console.log("gift userId = " + userId);
      // console.log("gift authState.userId = " + authState.userId);
      // console.log("gift customerState.info.authId = " + customerState.info.authId);
      // authState.userId !== null
      //   ? (userId = authState.userId)
      //   : (userId = customerState.info.authId);
      setLoader(true);
      try {
        let queryResponse = await Api.getDataSecure(
          URL.getBalance(userId),
          token
        );
        if (queryResponse.status === 200) {
          setLoader(false);
          setCurrentBalance(
            parseInt(queryResponse.data.accountBalanceInUSD.toFixed(2))
          );
        }
      } catch (error) {
        setLoader(false);
        notyf.open({
          type: "error",
          message: "unable to fetch available balance",
        });
      }
    }
    getBalance();
  }, [authState.token, authState.userId, notyf]);

  let logoList = [
    {
      logo: StarbuckLogo,
      productName: "StarBucks",
      value: "STARBUCKS",
    },
    {
      logo: AmazoneLogo,
      productName: "Amazon",
      value: "AMAZON",
    },
    {
      logo: DominosLogo,
      productName: "Domino's",
      value: "DOMINOS",
    },
    {
      logo: DunkinLogo,
      productName: "Dunkin Donuts",
      value: "DUNKIN_DONUTS",
    },
    {
      logo: GrubHub,
      productName: "GRUBHUB",
      value: "GRUBHUB",
    },
  ];

  const handleSelection = (e, productObj) => {
    setProduct(productObj);
  };

  const toggleModal = (value) => {
    if (isPermitted && value) {
      handleCardRequest();
    }
    if (!(selectedProduct && selectedProduct.value)) {
      notyf.open({
        type: "error",
        message: "please select gift card to redeem",
      });
    } else {
      setPermission(!isPermitted);
    }
  };

  const handleCardRequest = async () => {
    let inputjson = {};
    if (!(selectedProduct && selectedProduct.productName)) {
      notyf.open({
        type: "error",
        message: "please select the gift card",
      });
      return;
    }
    inputjson["productName"] = selectedProduct.value;
    inputjson["value"] = cardValue;
    let token = authState.token;
    let userId = customerState.info.authId;
    // console.log("userId = " + userId);
    // console.log("authState.userId = " + authState.userId);
    // console.log("customerState.info.authId = " + customerState.info.authId);
    // authState.userId !== null
    //   ? (userId = authState.userId)
    //   : (userId = customerState.info.authId);
    inputjson["auth0Id"] = userId;
    // inputjson["auth0Id"] = userId !== null ? userId : customerState.info.authId;
    setLoader(true);
    try {
      await Api.postDataSecure(URL.rewardGcow, token, inputjson);
      notyf.open({
        type: "success",
        message: "Gift card purchase successful! Check email.",
      });
      setAciveStep(activeStep + 1);
      setResponse(true);
      setLoader(false);
    } catch (errors) {
      setLoader(false);
      setResponse(false);
      setAciveStep(activeStep + 1);
      let finalStep = document.querySelectorAll("._1-y3h")[2];
      let checkParent = finalStep.querySelector("._ZFVon");
      checkParent.removeChild(checkParent.firstChild);

      let cross = document.createElement("img");
      cross.className = "cross_icon";
      cross.src = CrossImg;
      checkParent.append(cross);

      if (errors.data.violations && errors.data.violations[0]) {
        setError(
          "Gift Card " +
            errors.data.violations[0].fieldName +
            " " +
            errors.data.violations[0].message
        );
      } else {
        setError(
          "Failed to process request. Please check your available balance"
        );
      }
    }
  };

  const handleChange = (e) => {
    setCardValue(e.target.value);
  };

  const getCurrentStep = (step) => {
    let step1 = (
      <div>
        <Row>
          <Col>
            <Button
              className="next_nav_css"
              variant="primary"
              disabled={!selectedProduct}
              onClick={() => {
                if (selectedProduct) {
                  setAciveStep(activeStep + 1);
                } else {
                  notyf.open({
                    type: "error",
                    message: "please select gift card to redeem",
                  });
                }
              }}
            >
              Next {">>"}
            </Button>
          </Col>
        </Row>
        {isLoading && <Loader />}
        <div className="d-flex justify-content-center">
          <div className="d-inline w-85">
            <div className="custome_card_height">
              <Row className="px-1 pt-3 img-list-margin">
                {logoList.map((element) => {
                  return (
                    <Col
                      xs={12}
                      sm={5}
                      md={5}
                      xl={4}
                      xxl={3}
                      lg={5}
                      className="py-2"
                    >
                      <img
                        src={element.logo}
                        alt="Product Name"
                        className={
                          selectedProduct &&
                          selectedProduct.productName === element.productName
                            ? "img-logo_active"
                            : "img-logo border"
                        }
                        onClick={(e) => handleSelection(e, element)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
    );

    let step2 = (
      <div>
        <div className="margin-top-2rem">
          <span
            className="prev_nav_css"
            onClick={() => {
              setAciveStep(activeStep - 1);
            }}
          >
            {"<<"} Prev
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <div className="add_fund_card_outer">
            <div className="h-100">
              {isLoading && <Loader />}
              <Card className="">
                <Card.Body>
                  <div>
                    <div className="row">
                      <div className="col-md-6 col-xl-6 col-sm-12 col-xs-12 col-lg-6">
                        <div>
                          <span className="product_name">
                            {selectedProduct ? selectedProduct.productName : ""}
                          </span>
                        </div>
                        <div className="w-100 margin-top-2rem">
                          {selectedProduct && selectedProduct.logo ? (
                            <img
                              src={selectedProduct.logo}
                              className="selected-img-logo border "
                              alt="selected-gift"
                            />
                          ) : (
                            <img
                              src={NoGiftLogo}
                              className="selected-img-logo border "
                              alt="selected-gift"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-6 col-sm-12 col-xs-12 col-lg-6">
                        <div className="margin-top-2rem">
                          <span className="balance_str">
                            Available Balance :
                          </span>
                          <span className="float-end balance_str">
                            ${currentBalance}
                          </span>
                        </div>
                        <div>
                          <span className="balance_str">
                            Remaining Balance :
                          </span>
                          <span className="float-end balance_str">
                            {currentBalance - cardValue < 0 ? "-" : ""}$
                            {Math.abs(currentBalance - cardValue).toFixed(2)}
                          </span>
                        </div>
                        <div className="mt-4">
                          {cardValue > currentBalance ? (
                            <Alert variant="warning" className="text-center">
                              Can't redeem Gift Card for value more than Account
                              Balance.
                            </Alert>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mt-4">
                          <Form.Group>
                            <Form.Label className="float-end">
                              Value (in USD)
                            </Form.Label>
                            <Form.Control
                              id="value"
                              type="number"
                              name="value"
                              value={cardValue}
                              placeholder="Enter Amount"
                              onChange={(e) => handleChange(e)}
                              className="form-control-lg"
                            />
                          </Form.Group>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                {cardValue > currentBalance
                                  ? "not enough balance available"
                                  : cardValue <= currentBalance && cardValue
                                  ? "Submit"
                                  : "Please add the gift card value"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                {...triggerHandler}
                                ref={ref}
                                className="d-inline-block"
                              >
                                <Button
                                  variant="primary"
                                  onClick={() => toggleModal()}
                                  id="submit-button"
                                  disabled={
                                    cardValue <= 0 || cardValue > currentBalance
                                  }
                                >
                                  REDEEM
                                </Button>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );

    let step3 = (
      <div>
        <div>
          <div className="py-5">
            {!isSuccess ? (
              <div className="mb-5">
                <Alert variant="danger py-3">
                  <div className="d-block ">
                    <h3 className="text-center text-danger">
                      Failed to Redeem Gift Card...
                    </h3>
                  </div>
                  <div className="text-center">{error}</div>
                </Alert>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-center">
              <div className="d-inline">
                {selectedProduct && selectedProduct.logo ? (
                  <img
                    src={selectedProduct.logo}
                    className="selected-img-logo_final border "
                    alt="selected-gift"
                  />
                ) : (
                  <img
                    src={NoGiftLogo}
                    className="selected-img-logo_final border"
                    alt="selected-gift"
                  />
                )}
              </div>
            </div>
            <div className="">
              <div class="bottom-container">
                <h1 className="text-center text-white">You did it!</h1>

                {isSuccess ? (
                  <div>
                    <h3 className="text-center text-success py-5">
                      Gift card has been successfully purchased!
                    </h3>
                    <h3 className="text-center text-secoundary py-5">
                      Please check your email for the gift card details.
                    </h3>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-center pb-3">
                  <button
                    className="btn btn-lg btn-warning"
                    onClick={() => {
                      navigate("/gift");
                    }}
                  >
                    Go back to gift cards page.
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    switch (step) {
      case 1:
        return step1;
      case 2:
        return step2;
      case 3:
        return step3;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Invoice" />
      <Card className="py-1 pl-2">
        <Card.Body>
          <Row>
            <h1>Select a Gift Card To Begin</h1>
          </Row>
        </Card.Body>
      </Card>
      <Container fluid className="p-0 redeem_card_body">
        <Card className="d-flex flex-column">
          <Card.Header className="redeem_card_head">
            <div className="d-flex justify-content-center mt-3">
              <div className="d-inline w-75">
                <Stepper
                  color="#23b561"
                  fontSize="20px"
                  fontColor="#18aed6"
                  onClick={(e) => {
                    console.log(e);
                  }}
                  steps={[
                    { label: "Select" },
                    { label: "Add Value" },
                    {
                      label:
                        activeStep !== 3
                          ? "Success"
                          : isSuccess
                          ? "Success"
                          : "Failed",
                    },
                  ]}
                  currentStep={activeStep}
                />
              </div>
            </div>
          </Card.Header>
          <Card.Body className="redeem_card_body">
            {getCurrentStep(activeStep)}
          </Card.Body>
        </Card>
      </Container>
      {isPermitted && (
        <WarningModal
          isOpen={isPermitted}
          toggle={toggleModal.bind()}
          cardValue={cardValue}
          productName={selectedProduct.productName}
        />
      )}
    </React.Fragment>
  );
}

export default Redeem;
