import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import SendNanoForm from "./SendNanoForm";
import Statistics from "./Statistics";
import { getCustomerInfoSuccess } from "../../../redux/customer/actions";
// import { ConstantKey } from "../../../constants";
import { URL } from "../../../constants/url";
import * as Api from "../../../helper/api";
import { getUserGuid } from "../../../utils/auth0";

const ProfileUpdationUI = () => {
  const customerState = useSelector((state) => state.Customer);
  const [count, setCount] = useState(0);
  const [isEmailVerified, setEmailVerifiedFlag] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.Auth);

  /**
   * This SHOULD handle instances where an organization is not yet available for new customers
   */
  useEffect(() => {
    if (customerState.info.emailVerified) {
      setEmailVerifiedFlag(true);
      setCount(count + 1);
      console.log("Email Verified!");
    } else {
      console.log("customerState.info.emailVerified is not valid");
    }
    fetchUserInfo();
  }, [isEmailVerified]);

  /**
   * NEW
   * NOTE: the differences between returning authId (User) and auth0Id (Customer) are HORRIBLE.
   *  Serious delays when copying code from dashboard.fyncom.com to app.fyncom.com as the variable names REALLY screw you up.
   *  Suggestion: Update backend to return "auth0Id", not "authId" for the auth object.
   */
  const fetchUserInfo = async () => {
    try {
      let response = await Api.getDataSecure(
        URL.getUserInfo(
          customerState.info.authId === undefined
            ? getUserGuid(authState.user.sub)
            : customerState.info.authId
        ),
        authState.token
      );
      dispatch(getCustomerInfoSuccess(response.data));
      console.log("response data is " + JSON.stringify(response.data));
      if (response.data.emailVerified) {
        console.log("email is verified");
      } else {
        console.log("email is not verified yet");
      }
    } catch (error) {
      console.log("found error of " + error);
      // setUserInfo(null);
    }
  };

  return <p></p>;
};

const Default = () => {
  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <ToastContainer />
      <Container fluid className="p-0">
        <Header />
        <ProfileUpdationUI />
        <Statistics />
        <SendNanoForm />
      </Container>
    </React.Fragment>
  );
};

export default Default;
