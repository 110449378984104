import { CreditCard, Gift, Smile, Settings, Sliders } from "react-feather";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
  },
  {
    href: "/wallet",
    icon: CreditCard,
    title: "Wallet",
  },
  {
    href: "/gift",
    icon: Gift,
    title: "Gift",
  },
  {
    href: "/referrals",
    icon: Smile,
    title: "Referrals",
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
