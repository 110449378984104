export const URL = {
  rewardGcow: "/rewards/gcow",
  getBalance: (id) => {
    return `/nano/user/${id}/balance`;
  },
  updateUser: "/user/update",
  updateUserPrefs: "/preference/update",
  updateCrowdin: "/webhooks/update/service",
  getGiftCardHistory: (auth0Id) => `/rewards/user/${auth0Id}/query`,
  // getReferalCode: () => "/nano/getReferral",
  getReferalCode: () => "/v2/referral/fetch",
  sendNano: "/nano/user/send",
  getUserInfo: (id) => `/user/${id}/info`,
  sendCode: (code, verifier) =>
    `/v2/api/auth/google/callback/test/${code}/${verifier}`,
};
