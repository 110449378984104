import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/lib/integration/react";
import Auth0ProviderWithNavigate from "./auth/auth0-provider-with-navigate";
import Loader from "./components/Loader";
import { LayoutProvider } from "./contexts/LayoutContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import "./i18n";
import { persistor, store } from "./redux/store";
import routes from "./routes";
import ChartJsDefaults from "./utils/ChartJsDefaults";

const App = () => {
  const content = useRoutes(routes);
  const isProduction = process.env.NODE_ENV === "production";
  const titleTemplate = isProduction ? "FynCom App" : "FynCom Local App";
  const defaultTitle = isProduction ? "FynCom App" : "FynCom Local App";

  return (
    <HelmetProvider>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <ThemeProvider>
            <SidebarProvider>
              <LayoutProvider>
                <ChartJsDefaults />
                <Auth0ProviderWithNavigate>{content}</Auth0ProviderWithNavigate>
              </LayoutProvider>
            </SidebarProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
