import produce from "immer";
import { CustomerTypes } from "./types";

const initialState = {
  loading: false,
  info: {
    organizations: [],
  },
};

export const Customer = (state = initialState, action) => {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case CustomerTypes.GET_INFO:
        draftState.loading = true;
        break;
      case CustomerTypes.GET_INFO_SUCCESS:
        draftState.loading = false;
        draftState.info = action.payload.customerInfo;
        break;
      case CustomerTypes.CREATE_ORGANIZATION_SUCCESS:
      case CustomerTypes.GET_ORGANIZATION_INFO_SUCCESS:
        draftState.info.organizations = action.payload;
        break;
      case CustomerTypes.SET_AUTH_TOKEN:
        draftState.token = action.payload;
        break;
      case CustomerTypes.CLEAR_CUSTOMER:
        draftState.info = { organizations: [] };
        break;
      default:
        break;
    }
  });
  return nextState;
};
